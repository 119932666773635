section.filter {
  margin-bottom: rem-calc(30);
  .filter-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px $dark-blue solid;
    @include breakpoint(small only) {
      flex-direction: column;
      align-items: center;
    }
    li {
      display: inline-block;
      a {
        display: block;
        padding: rem-calc(25 25);
        color: $dark-blue;
        transition: background .25s ease-in-out;
        &:hover {
          background: $background-grey;
        }
        &:active {
          background: $primary-yellow;
        }
      }
    }
  }
}

.filter-active {
  background: $primary-yellow;
}
