#wsp-history {
  .header {
    padding: rem-calc(70 0);
    color: $secondary-blue;
    h3 {
      padding-bottom: rem-calc(35);
      font: {
        weight: 300;
      }
      strong {
        font-weight: 500;
      }
    }
  }

  //Slick Buttons
  .history-slider-btns {
    padding-bottom: rem-calc(20);
    i {
      transition: .25s ease-in-out;
    }
    i:hover {
      cursor: pointer;
      opacity: .5;
    }
  }
}

#history-slider-container {
  overflow: hidden;
  .history-slide {
    padding: rem-calc(115 0);
    background-position: center center;
    .slide-info {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.history-reveal, .team-reveal, .author-reveal {
  padding: 0;
  .img {
    background-position: center center;
    background-repeat: no-repeat;
    padding: rem-calc(200 0);
  }

  &.team-reveal .img {
    padding: rem-calc(100 0);
  }

  .modal-info {
    padding: rem-calc(35);
    h3 {
      padding-bottom: rem-calc(10);
    }
  }

  .close-button {
    color: $primary-yellow;
    background: $banner-blue;
    top: 0;
    right: 0;
    padding: rem-calc(10 15);
    font: {
      weight: 300;
      size: rem-calc(35);
    }
    transition: .25s ease-in-out;
    &:hover span{
      opacity: .7;
    }
  }
}


#about{
  .header {
    padding: rem-calc(70 0);
    h3 {
      padding-bottom: rem-calc(15);
      font: {
        weight: 300;
      }
      strong {
        font-weight: 500;
      }
    }
    h4 {
      padding-bottom: rem-calc(15);
      color: $secondary-blue;
      font: {
        size: rem-calc(20);
        weight: 500;
      }
    }
  }
}

#authors-and-wsp-team {
  background: $banner-blue;
  padding: rem-calc(75 0);

  .header {
    padding-bottom: rem-calc(20);
    @include breakpoint(large) {
      padding-bottom: rem-calc(0);
    }

    h3 {
      padding-bottom: rem-calc(25);
      color: $white;
      font: {
        weight: 300;
      }
      strong {
        font-weight: 500;
      }
    }

    p {
      color: $white;
    }
  }

  .divider {
    padding: rem-calc(0 20);
  }

  .authors {
    letter-spacing: 0;
    @include breakpoint(large) {
      padding: rem-calc(40 0);
      // align-items: flex-start;
    }
    @include breakpoint(small) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: rem-calc(20 0);
    }

    .author-image {
      height: rem-calc(300);
      width: rem-calc(350);
      background: {
        size: cover;
        position: center center;
      }
      &:hover {
        cursor: pointer;
      }
    }

    img {
      padding-bottom: rem-calc(20);
    }

    h4 {
      padding: rem-calc(20 0 15 0);
      color: $white;
      font: {
        size: 1.5rem;
        weight: 500;
      }
    }

    p {
      padding-bottom: rem-calc(20);
      color: $white;
      @include breakpoint(small only) {
        text-align: center;
      }
    }

    .author-button {
      color: $white;
      font: {
        size: rem-calc(20);
        weight: 500;
      }

      i {
        color: $primary-yellow;
      }

    // on hover, reveals ">" on button.
      .hover-state {
        opacity: 0;
    		transform: translateX(20px);
        transition: .25s ease-in-out;
      }
      &:hover .hover-state {
        opacity: 1;
    		transform: translateX(0px);
      }
    }
  }
}


#team {
  padding: rem-calc(75 0);

  .header {
    padding-bottom : rem-calc(15);

    h3 {
      padding-bottom: rem-calc(25);
      color: $banner-blue;
      font: {
        weight: 300;
      }
      strong {
        font-weight: 500;
      }
    }

    p {
      color: $secondary-blue;
    }
  }

  .team-rows {
    @include breakpoint(large) {
      padding-top: rem-calc(25);
    }

    .center {
      flex-direction: column;
      align-items: center;
      @include breakpoint(large) {
        align-items: left;
      }

      .member-img {
        height: rem-calc(200);
        width: rem-calc(255);
        background: {
          size: cover;
          repeat: no-repeat;
          position: center center;
        }
        &:hover {
          cursor: pointer;
        }
      }

      h5 {
        padding: rem-calc(10 0);
        color: $banner-blue;
        line-height: 1;
        font: {
          size: 1.5rem;
          weight: 500;
        }
      }

      h6 {
        padding-bottom: rem-calc(20);
        color: $secondary-blue;
        font: {
          size: rem-calc(15);
          weight: 400;
        }
        @include breakpoint(large) {
          padding-bottom: rem-calc(0);
        }
      }
    }
  }
}
