#feedback-form {
  position: relative;
  padding: rem-calc(60 0 550 0);
  background-position: center top;
  .header {
    h1 {
      font-weight: 300;
      line-height: $subheader-lineheight;
      strong {
        font-weight: 500;
      }
    }
    p {
      color: $primary-blue;
    }
  }

  // gf form
  #gravity-form {
    margin: auto;
    position: absolute;
    bottom: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    .container {
      opacity: 0;
      background: $banner-blue;
      padding: rem-calc(50 20);
      @include breakpoint(topbar) {
        padding: rem-calc(50 80);
      }
      .gfield_contains_required {
        label {
          display: none;
        }
      }

      //general styings
      li {
        padding: rem-calc(0 0 8 0);
        margin-top: 0;
        .ginput_container {
          margin-top: 0;

          input, textarea {
            &::-webkit-input-placeholder {
              color: $secondary-blue;
            }
            &:-moz-placeholder {
              color: $secondary-blue;
              opacity: 1;
            }
            &::-moz-placeholder {
              color: $secondary-blue;
              opacity: 1;
            }
            &:-ms-input-placeholder {
              color: $secondary-blue;
            }
          }
          textarea {
            height: rem-calc(200);
          }
        }
      }

      .gform_confirmation_message {
        color: $white;
        text-align: center;
        font: {
          size: rem-calc(30);
          weight: 300;
        }
      }

      //ratings stylings
      .wsp-rating {
        padding-left: rem-calc(10);
        label {
          color: $white;
          line-height: $border-lineheight;
          font: {
            weight: 300;
            size: 1.5rem;
          }
        }
        .gsurvey-rating {
          margin-bottom: rem-calc(20);
        }
      }

      //checkbox stylings
      .wsp-checkboxes {
        label {
          color: $white;
          line-height: $border-lineheight;
          font: {
            weight: 300;
            size: 1.5rem;
          }
        }
        .ginput_container_checkbox {
          li {
            margin-bottom: 0;
            input {
              margin-bottom: 0;
              margin-top: 0;
              -webkit-appearance: none;
            	background-color: #fafafa;
            	border: 1px solid #cacece;
            	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
            	padding: 15px;
            	display: inline-block;
            	position: relative;
              outline: none;
              &:active, &:checked:active {
                box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
              }
              &:checked {
                background-color: #e9ecee;
              	border: 1px solid #adb8c0;
              	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
              	color: #99a1a7;
              }
              &:checked:after {
                content: '\f00c';
                font-family: fontAwesome;
              	font-size: 25px;
              	position: absolute;
              	top: 1px;
              	left: 3px;
              	color: $dark-blue;
              }
            }
            label {
              margin: rem-calc(0 0 5 10);
              font: {
                size: rem-calc(15);
              }
            }
          }
        }
      }

      //Submit Button
      .gform_footer {
        display: none;
      }
    }
  }
}

.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
    font-size: inherit;
    font-family: inherit;
    padding: 5px 4px;
    letter-spacing: normal;
    width: 100%;
}


//Change star size
.gsurvey-rating:not(:checked) > label, .gsurvey-rating:not(:checked) > label:hover, .gsurvey-rating:not(:checked) > label:hover ~ label, .gsurvey-rating:not(:checked) > label, .gsurvey-rating:not(:checked) > label:hover, .gsurvey-rating:not(:checked) > label:hover ~ label, .gsurvey-rating > input:checked ~ label {
  width: 40px;
  line-height: 50px;
  background-size: 40px 40px;
  color: rgba(221, 221, 221, 0) !important;
  @include breakpoint(small only) {
    width: 20px;
    background-size: 20px 20px;
  }
}
