/**
 Wordpress Blog Styles
**/
.entry-content {
	img {
		margin-bottom: 0;

		&.alignleft {
			float: left;
			margin-right: 1rem;
		}

		&.alignright {
			float: right;
			margin-left: 1rem;
		}

		&.aligncenter {
			display: block;
			margin: 0 auto 1rem auto;
		}
	}

	figure.wp-caption {
		margin: 0;

		&.alignleft {
			float: left;
			margin: 0 1rem 0 0;
		}

		&.alignright {
			float: right;
			margin: 0 0 0 1rem;
		}

		&.aligncenter {
			display: block;
			margin: 0 auto 1rem auto;
		}

		.wp-caption-text {
			font-size: rem-calc(12);
			font-weight: bold;
		}
	}
}

// h, no margins
h1,h2,h3,h4,h5,h6,p {
	margin: 0;
}

h1,h2,h3 {
	color: $primary-blue;
}

p {
	color: $secondary-blue;
}

// BUTTONS
a {
  color: black;
}

//////////////////
// PRIMARY BUTTON
//////////////////
.button-primary {
	background: $primary-yellow;
  color: $primary-blue;
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
  &:hover {
    background: $hover-yellow;
    color: $primary-blue;
  }

// on hover, reveals ">" on button.
  .hover-state {
    opacity: 0;
		transform: translateX(20px);
    transition: .25s ease-in-out;
  }
  &:hover .hover-state {
    opacity: 1;
		transform: translateX(0px);
  }
	&:focus {
		color: $primary-blue;
		background: $hover-yellow;
	}
}


//////////////////
// PUZZLE BUTTON
//////////////////
.button-puzzle {
  position: relative;
  height: rem-calc(120);
  width: rem-calc(825);
  background-color: $primary-blue;
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  font-size: 30px;
  font-weight: 300;
	line-height: rem-calc(60);
	@include breakpoint(tablet only) {
		width: rem-calc(625);
	}
  &:hover {
    background: $dark-blue;
    background-size: contain;
    background-repeat: no-repeat;
  }
	&:focus {
    background-color: $banner-blue;
	}

  .arrow {
    position: absolute;
    right: 50px;
		top: 50%;
    transform: translateY(-50%);
  }
}

// Mobile version

.button-puzzle-mobile {
	background-color: $primary-blue;
	padding: rem-calc(50 50);
	font-size: rem-calc(18);
  font-weight: 300;
	&:hover {
		background: $dark-blue;
	}
	&:focus {
    background-color: $banner-blue;
	}
}

.wsywig {
	color: $secondary-blue;
	a {
		color: $secondary-blue;
		transition: .25s ease-in-out;
		&:focus {
			color: $secondary-blue;
		}
		&:hover {
			opacity: .7;
		}
	}
	h3 {
		padding-bottom: rem-calc(35);
		font: {
			weight: 300;
		}
		strong {
			font-weight: 500;
		}
	}
	p {
		font-size: rem-calc(15);
	}
}

.button-container {
  padding-top: rem-calc(40);
}

#location {
  .location-info {
    @include breakpoint(large) {
      text-align: left;
    }
    h3 {
      padding-bottom: rem-calc(20);
      color: $banner-blue;
      font: {
        weight: 500;
      }
    }
    h4 {
      padding-bottom: rem-calc(10);
      color: $banner-blue;
      font: {
        size: rem-calc(20);
        weight: 500;
      }
    }

    .button {
      margin-top: rem-calc(30);
    }
  }
}

// Default Template
.default {
	padding: rem-calc(100 0);
}

// Read More
$font-size: 26px;
$line-height: 1.4;
$lines-to-show: 6;

.more {
 &.show-less {
  	display: block; /* Fallback for non-webkit */
   display: -webkit-box;
   max-width: 100%;
   height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
   margin: 0 auto;
   font-size: $font-size;
   line-height: $line-height;
   -webkit-line-clamp: $lines-to-show;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
 }
}

.show-toggle {
  a {
    text-decoration: underline;
  }
}
