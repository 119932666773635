.testimonial {
  background: $background-grey;
  padding: rem-calc(30 30);
  margin-bottom: rem-calc(30);
  opacity: 0;
    .content-wrapper {
      @include breakpoint(medium down) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: rem-calc(20);
        padding-bottom: rem-calc(20);
      }
      @include breakpoint(small only) {
        padding-left: 0;
      }
      img {
        height: 250px;
        width: 275px;
      }
    h3 {
      position: relative;
      font-weight: 500;
      line-height: 1.1;
      color: $banner-blue;
      padding-top: 20px;
      padding-bottom: 20px;
      .border-right {
        border-bottom: 2px solid $banner-blue;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 35px;
        @include breakpoint(medium down) {
          left: 0;
        }
      }
    }
    .wrapper {
      padding-top: rem-calc(12);
      h6 {
        font: {
          weight: 300;
          size: rem-calc(15);
        }
        color: $secondary-blue;
        line-height: $lead-lineheight;
        @include breakpoint(medium down) {
          text-align: left;
        }
      }
    }
  }
  p {
    font-weight: 300;
    color: $secondary-blue;
    padding-left: rem-calc(20);
    padding-right: rem-calc(50);
    @include breakpoint(small only) {
      padding: 0;
    }
  }
}


//////////////////
// TESTIMONIAL SLIDER
//////////////////

.testimonial-slider {
  width: 85vw;
  border: none;
  @include breakpoint(large) {
    width: 95vw;
    padding: rem-calc(25 140 25 130);
  }
  @include breakpoint(xxlarge) {
    width: 75vw;
  }
  .testimonial-slide {
    outline: none;
    background: $background-grey;
    padding: rem-calc(5);
    position: relative;
    @include breakpoint(topbar down) {
      margin-top: rem-calc(20);
    }
    @include breakpoint(medium) {
      padding: rem-calc(25 45);
    }
    h3 {
      padding-top: rem-calc(20);
      position: relative;
      font-weight: 500;
      line-height: 1.4;
    }
    .wrapper {
      h6 {
        font: {
          weight: 300;
          size: rem-calc(15);
        }
        color: $secondary-blue;
        line-height: 1.4;
        @include breakpoint(medium down) {
          text-align: left;
        }
      }
      .slick-btn-container {
        padding-top: rem-calc(20);
        i {
          transition: .25s ease-in-out;
          &:hover {
            cursor: pointer;
            opacity: .5;
          }
        }
      }
    }
  }
}


.slick-prev, .slick-next {
  display: none;
}

// .slide-img {
//   position: absolute;
//   background-position: center center;
//   height: 100%;
//   width: 35%;
//   top: 0;
//   right: 0;
//   @include breakpoint(large down) {
//     display: none;
//   }
// }

.slide-img {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
