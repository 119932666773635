section.banner {
  background: $banner-blue;
  padding: rem-calc(50 0);
  .img {
    display: flex;
    justify-content: center;
  }

    h3 {
      line-height: 1.2;
      font: {
        weight: 300;
      }
      color: $white;
      strong {
        font-weight: 400;
      }
      @include breakpoint(small only) {
        text-align: center;
      }
    }
    p {
      color: $white;
      margin-bottom: rem-calc(25);
      @include breakpoint(small only) {
        text-align: center;
      }
    }
    ul {
      list-style: none;
      margin: 0;
      li {
        color: $white;
        &:before {
          content: '\f105';
          padding: rem-calc(0 15 0 0);
          font: {
            weight: bold;
            size: 18px;
            family: fontAwesome;
          }
        }
      }
    }

    img {
      @include breakpoint(small only) {
        padding-bottom: rem-calc(30);
      }
    }

  .center {
    @include breakpoint(small only) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.single {
  margin-top: rem-calc(30);
  a {
    margin-top: rem-calc(20);
  }
}
