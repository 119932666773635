#pricing {
  padding: rem-calc(75 0);

  .header {
    padding-bottom: rem-calc(50);
    h3 {
      font: {
        size: rem-calc(30);
        weight: 300;
      }
      strong {
        font-weight: 500;
      }
    }
  }

  .access-card {
    opacity: 0;
    background: $background-grey;
    margin-bottom: rem-calc(30);
    padding: rem-calc(40);

    .card-title {
      display: flex;
      justify-content: space-between;
      padding-bottom: rem-calc(20);
      border-bottom: 2px $banner-blue solid;
    }

    .card-details {
      padding-top: rem-calc(20);
    }

    .card-button {
      padding-top: rem-calc(20);
    }
  }

  .access-puzzle-button {
    .button-puzzle {
      position: relative;
      height: rem-calc(120);
      width: 100%;
      background-color: $primary-blue;
      background-size: contain;
      background-repeat: no-repeat;
      border: none;
      font-size: 30px;
      font-weight: 300;
    	line-height: rem-calc(40);
      // padding: rem-calc(20 100);
    	@include breakpoint(tablet) {
    		padding: rem-calc(20 100);
    	}
      @include breakpoint(large) {
        padding: rem-calc(20 200);
      }
      @include breakpoint(xxlarge) {
        padding: rem-calc(20 240);
      }
      &:hover {
        background: $dark-blue;
        background-size: contain;
        background-repeat: no-repeat;
      }
    	&:focus {
        background-color: $banner-blue;
    	}

      .arrow {
        position: absolute;
        right: 50px;
    		top: 50%;
        transform: translateY(-50%);
      }
    }

    // Mobile version
    .button-puzzle-mobile {
    	background-color: $primary-blue;
    	padding: rem-calc(50 50);
    	font-size: rem-calc(18);
      font-weight: 300;
      width: 100%;
    	&:hover {
    		background: $dark-blue;
    	}
    }
  }
}

// Modal
.reveal {
  padding: 0;
  border: none;
  outline: none;

  .gform_wrapper {
    margin: 0;

    input {
      &::-webkit-input-placeholder {
        color: $secondary-blue;
      }
      &:-moz-placeholder {
        color: $secondary-blue;
        opacity: 1;
      }
      &::-moz-placeholder {
        color: $secondary-blue;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: $secondary-blue;
      }
    }

    select {
      color: $secondary-blue;
    }

    #field_3_3, #field_5_3 {
      background: $dark-blue;
      margin: 0;
      padding: rem-calc(60 60);

      label {
        color: $white;
        font: {
          size: rem-calc(30);
          weight: 300;
        }
      }

      .ginput_container {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;

        span {
          color: $white;
        }
      }
    }

    #field_3_1, #field_5_1 {
      background: $banner-blue;
      margin: 0 !important;
      padding: rem-calc(30 60);
      width: 100%;

      label {
        color: $white;
        font: {
          size: rem-calc(30);
          weight: 300;
        }
      }

      #input_3_1_2_cardinfo_left {
        width: 100%;



        .ginput_card_expiration_container .ginput_card_field {
          width: 100%;
        }

        select.ginput_card_expiration.ginput_card_expiration_month {
          width: 100% !important;
          margin-right: 0;
        }
      }
    }
  }

  .gform_footer {
    padding: rem-calc(0 60 60 60);
    background: $banner-blue;
    margin: 0;

    .gform_button {
      background: $primary-yellow;
      color: $banner-blue;
      font-weight: 300;
      padding-left: rem-calc(20);
      padding-right: rem-calc(20);
      &:hover {
        background: $hover-yellow;
        color: $banner-blue;
      }

    // on hover, reveals ">" on button.
      .hover-state {
        opacity: 0;
    		transform: translateX(20px);
        transition: .25s ease-in-out;
      }
      &:hover .hover-state {
        opacity: 1;
    		transform: translateX(0px);
      }
    	&:focus {
    		color: $banner-blue;
    		background: $hover-yellow;
    	}
    }
  }

  .close-button {
    color: $primary-yellow;
  }
}

.gform_wrapper .ginput_complex .ginput_cardinfo_left span.ginput_card_expiration_container select {
  margin-bottom: 10px;
}

.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield select {
  width: 100% !important;
}

.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield select {
  margin-left: 0;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_left select.ginput_card_expiration.ginput_card_expiration_month {
  margin-right: 0;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_right span.ginput_card_security_code_icon {
  top: -6px;
  left: 0px;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_left span.ginput_card_expiration_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_left, .gform_wrapper .ginput_complex .ginput_cardinfo_right {
  width: 100%;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_right input.ginput_card_security_code {
  max-width: 100%!important;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_right span.ginput_card_security_code_icon {
  display: none;
}
