.hero-404 {
  position: relative;
  // padding: rem-calc(200 0);
  height: 75vh;
  z-index: 0;
  &:before {
    position: absolute;
    background: $overlay-blue;
    top: 0;
    height: 100%;
    width: 100%;
    content: '';
    z-index: -1;
  }

  .container-404 {
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      color: $white;
      font-weight: 300;
      strong {
        font-weight: 500;
      }
    }

    p {
      padding-top: rem-calc(10);
      color: $white;
      font-weight: 300;
    }

    .buttons {
      padding-top: rem-calc(30);
      a {
        margin: rem-calc(0 10);
        @include breakpoint(small only) {
          margin-bottom: rem-calc(15);
        }
      }
    }
  }
}
