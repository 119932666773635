.hero {
  position: relative;
  padding: rem-calc(48 0);
  z-index: -1;
  background: {
    size: cover;
    position: center;
  }
  &:before {
    position: absolute;
    background: $overlay-blue;
    top: 0;
    height: 100%;
    width: 100%;
    content: '';
    z-index: -1;
  }
  h1 {
    color: $white;
    font-weight: 300;
    strong {
      font-weight: 500;
    }
  }
  p {
    padding-top: rem-calc(10);
    color: $white;
  }
}

////////////////////////
//  LARGE HERO
////////////////////////

.hero-large {
  padding: rem-calc(90 0);
  h1 {
    font: {
      weight: 100;
      size: 55px;
    }
    line-height: $lead-lineheight;
    strong {
      font-weight: 500;
    }
  }
  h3 {
    line-height: 4.5;
  }

  @include breakpoint(medium down) {
    .hero-text {
      text-align: center;
    }
  }
}
