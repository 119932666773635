#wsp-certifications {
  //hero
  .header {
    padding: rem-calc(75 0);
    h1 {
      padding-bottom: rem-calc(20);
      font-weight: 300;
      strong {
        font-weight: 500;
      }
    }
  }

  //Slick Buttons
  .history-slider-btns {
    padding-bottom: rem-calc(20);
    i {
      transition: .25s ease-in-out;
    }
    i:hover {
      cursor: pointer;
      opacity: .5;
    }
  }
}

#certification-slider-container {
  overflow: hidden;
  .certification-slide {
    padding: rem-calc(115 0);
    background-position: center center;
    .slide-info {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
