.tabs-system {
  .header {
    padding: rem-calc(50 0);
    h3 {
      padding-bottom: 0;
      line-height: $lead-lineheight;
      font: {
        weight: 300;
      }
      strong {
        font-weight: 500;
      }
    }
    p {
      padding-top: rem-calc(35);
    }
  }

  //Tabs
  .tabs {
    display: flex;
    justify-content: center;
    border-top: 1px $banner-blue solid;
    border-bottom: 1px $banner-blue solid;
    border-right: none;
    border-left: none;
    .tabs-title {
      & > a:focus, .tabs-title > a[aria-selected='true'] {
        background: $primary-yellow;
        color: $banner-blue;
      }
      a {
        color: $banner-blue;
        font: {
          size: rem-calc(20);
          weight: 300;
        }
      }
    }
    .is-active {
      a {
        background: $primary-yellow;
      }
    }
  }

  //Tabs Content
  .container {
    background: $background-grey;
    .tabs-content {
      margin: rem-calc(65 0 75 0);
      border: none;
      background: $background-grey;
      h3 {
        padding-top: rem-calc(10);
        padding-bottom: rem-calc(10);
        font: {
          size: rem-calc(20);
          weight: 500;
        }
        strong {
          font-weight: 500;
        }
      }

      ul {
        color: $secondary-blue;
        li {
          strong {
            font-weight: 500;
          }
        }
      }
    }
  }

// MOBILE
  .accordion-title {
    position: relative;
    display: block;
    padding: 1.25rem 1rem;
    border: 1px solid #e6e6e6;
    border-bottom: 0;
    font-size: 0.75rem;
    line-height: 1;
    color: $banner-blue;
    background: $primary-yellow;
    &:hover, &:focus {
      background: $primary-yellow;
    }
  }
}

.content-img {
  padding-bottom: rem-calc(20);
  padding-top: rem-calc(10);
  @include breakpoint(large) {
    padding-bottom: rem-calc(0);
  }

  img {
    display: none;
    margin-bottom: rem-calc(75);

    &.show-more-img, &.show-img {
      display: inline-block;
    }
  }

}
