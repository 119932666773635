//////////////////
// ALIGNMENT
//////////////////

#wsp-alignment {
  padding: rem-calc(70 0);
  .header {
    padding-bottom: rem-calc(35);
    h3 {
      line-height: $lead-lineheight;
      font: {
        weight: 300;
      }
      strong {
        font-weight: 500;
      }
    }
  }

  .details {
    p {
      color: $secondary-blue;
    }
  }

  .alignment-boxes {
    padding: rem-calc(50 0 0 0);
    h3 {
      padding: rem-calc(0 0 20 0);
      font-size: rem-calc(16);
    }
    p {
      padding-bottom: rem-calc(30);
      @include breakpoint(large) {
        padding-bottom: 0;
      }
    }
    a {
      margin-top: rem-calc(30);
    }
  }
}


//////////////////
// HOW APPLICATIONS
//////////////////

#how-does-wsp-help {
  .header {
    padding: rem-calc(0 0 75 0);
    @include breakpoint(medium) {
      padding: rem-calc(200 0 75 0);
    }
    @include breakpoint(large) {
      padding: rem-calc(375 0 75 0);
    }
    h3 {
      padding-bottom: rem-calc(35);
      line-height: $lead-lineheight;
      font: {
        weight: 300;
      }
      strong {
        font-weight: 500;
      }
    }
  }
}

.footer-image {
  padding: rem-calc(100 0);
}
