#contact-us-form {
  position: relative;
  padding: rem-calc(60 0 550 0);
  .header {
    h1 {
      font-weight: 300;
      line-height: $subheader-lineheight;
      strong {
        font-weight: 500;
      }
    }
  }

  // gf form
  #gravity-form {
    margin: auto;
    position: absolute;
    bottom: 200px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    .container {
      opacity: 0;
      background: $banner-blue;
      padding: rem-calc(50 20);
      @include breakpoint(topbar) {
        padding: rem-calc(50 80);
      }

      //Form Heading
      .form-heading {
        border-bottom: 2px $white solid;
        margin-bottom: rem-calc(20);
        padding-bottom: rem-calc(20);
        h3, p {
          color: $white;
        }
        h3 {
          font-weight: 500;
        }
        p {
          font-weight: 300;
        }
        .email {
          color: $white;
          font-weight: 500;
          transition: color .25s ease-in-out;
          &:hover {
            color: $third-blue;
          }
        }
      }

      // Form Title
      .form-title {
        h3 {
          color: $white;
          font: {
            weight: 300;
          }
          strong {
            font-weight: 500;
          }
        }
      }

      .gfield_contains_required {
        label {
          display: none;
        }
      }


      #field_2_5 {
        label {
          color: $white;
          padding-bottom: rem-calc(10);
        }
      }

      //general styings
      li {
        padding: rem-calc(0 0 8 0);
        margin-top: 0;
        label {
          color: $white;
          padding-bottom: rem-calc(0);
          font: {
            size: rem-calc(20);
            weight: 300;
          }
        }
        .ginput_container {
          margin-top: 0;

          input, textarea {
            &::-webkit-input-placeholder {
              color: $secondary-blue;
            }
            &:-moz-placeholder {
              color: $secondary-blue;
              opacity: 1;
            }
            &::-moz-placeholder {
              color: $secondary-blue;
              opacity: 1;
            }
            &:-ms-input-placeholder {
              color: $secondary-blue;
            }
          }
          textarea {
            height: rem-calc(200);
          }
        }
      }

      #field_2_6, #field_2_7 {
        label {
          display: none;
        }
      }

      .gform_confirmation_message {
        color: $white;
        text-align: center;
        font: {
          size: rem-calc(30);
          weight: 300;
        }
      }

      //checkbox stylings
      .wsp-checkboxes {
        label {
          color: $white;
          line-height: 3;
          font: {
            weight: 300;
            size: 1.5rem;
          }
        }

        .gfield_label {
          line-height: $subheader-lineheight;
          padding-bottom: 0;
        }

        .ginput_container_checkbox {
          li {
            margin-bottom: 0;
            input {
              margin-bottom: 0;
              margin-top: 0;
              -webkit-appearance: none;
            	background-color: #fafafa;
            	border: 1px solid #cacece;
            	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
            	padding: 15px;
            	display: inline-block;
            	position: relative;
              outline: none;
              &:active, &:checked:active {
                box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
              }
              &:checked {
                background-color: #e9ecee;
              	border: 1px solid #adb8c0;
              	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
              	color: #99a1a7;
              }
              &:checked:after {
                content: '\f00c';
                font-family: fontAwesome;
              	font-size: 25px;
              	position: absolute;
              	top: 1px;
              	left: 3px;
              	color: $dark-blue;
              }
            }
            label {
              margin: rem-calc(0 0 0 10);
              font: {
                size: rem-calc(15);
              }
            }
          }
        }
      }

      //Submit Button
      .gform_footer {
        display: none;
      }
    }
  }
}

.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
    font-size: inherit;
    font-family: inherit;
    padding: 5px 4px;
    letter-spacing: normal;
    width: 100%;
}
