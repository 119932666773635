footer.site-footer {
  background: $dark-blue;
  padding: rem-calc(45 0);
  p {
    color: $white;
    font-size: rem-calc(12);
    @include breakpoint(large) {
      padding-left: rem-calc(20);
    }
  }
  h6, a {
    color: $white;
    font-size: rem-calc(12);
    transition: opacity .25s ease-in-out;
  }
  a:hover {
    opacity: .5;
  }
  img {
    padding-bottom: rem-calc(30);
    height: rem-calc(70);
  }
  .sm-container {
    @include breakpoint(large down) {
      display: flex;
      justify-content: flex-start;
      margin-top: rem-calc(20);
    }
    a {
      .sm-icons {
        padding: rem-calc(0 0 0 35);
        color: $primary-yellow;
        font-size: rem-calc(18);
        @include breakpoint(large down) {
          padding: rem-calc(0 35 0 0);
        }
      }
    }
  }
}
