#getting-started {
  background: $background-grey;
  padding: rem-calc(35 0);
  img {
    opacity: 0;
  }
  h1 {
    line-height: $subheader-lineheight;
    font-weight: 300;
    strong {
      font-weight: 500;
    }
  }
}

#wsp-access-code {
  padding: rem-calc(75 0);
  .title {
    padding: rem-calc(0 0 50 0);
    h2 {
      font-size: 1.5rem;
    }
  }
}
