/*===========================================================*/
/*						     Liquid Theme Custom Sass                   */
/*===========================================================*/

/*=================== Foundation Core =======================*/
@import 'foundation';
@import '/wp-content/themes/liquid/assets/vendor/animate.css/animate.min.css';

/*======================== Vendor ===========================*/
 @import '../vendor/slick.js/slick/slick.scss';
 @import '../vendor/slick.js/slick/slick-theme.scss';

/*================= Site Layouts/Mixins =====================*/
@import 'mixins';
@import "layouts/_404.scss";
@import "layouts/_about.scss";
@import "layouts/_banner.scss";
@import "layouts/_certifications.scss";
@import "layouts/_contact-us.scss";
@import "layouts/_faq.scss";
@import "layouts/_feedback.scss";
@import "layouts/_fitler.scss";
@import "layouts/_footer.scss";
@import "layouts/_getting-started.scss";
@import "layouts/_hero.scss";
@import "layouts/_home.scss";
@import "layouts/_nav.scss";
@import "layouts/_other-wsp-products.scss";
@import "layouts/_samples.scss";
@import "layouts/_site.scss";
@import "layouts/_tabs-system.scss";
@import "layouts/_testimonial.scss";
@import "layouts/_what-is-wsp.scss";
@import "layouts/_wsp-access-code.scss";

// Work Sans Font - Light:300, Regular: 400, Medium:500
@import url('https://fonts.googleapis.com/css?family=Work+Sans:300,400,500');
