.inventory-card {
  padding-bottom: rem-calc(75);
  opacity: 0;
}

#inventory-sample {
  .title {
    padding: rem-calc(75 0);
    h3 {
      padding-bottom: rem-calc(35);
      font: {
        // size: rem-calc(40);
        weight: 300;
      }
      strong {
        font-weight: 500;
      }
    }
  }

  .samples {
    background: $background-grey;
    padding: rem-calc(50 75 0 75);
    border-bottom: 2px $banner-blue solid;
    @include breakpoint(small only) {
      padding: rem-calc(50 25 0 25);
    }

    section.preference-assessment {
    	header {
    		margin-bottom: 3rem;
    	}

    	section.confirmation {
    		background: $white;

    		h3 {
    			margin: 3rem 0 1rem 0;
    		}

    		.button {
    			margin-top: 2rem;
    		}

    		@include breakpoint(small only) {
    			padding: 2rem 0;
    		}
    	}

    	@include breakpoint(small only) {
    		margin-top: 3rem;
    	}
    }

    section.assessment-group {
    	margin-bottom: 3.5rem;
    	position: relative;

    	&.disabled {
    		opacity: .5;

    		.disabled-overlay {
    			position: absolute;
    			width: 100%;
    			height: 100%;
    		}
    	}

      .question-section-header {
        h3 {
          padding-bottom: rem-calc(35);
          span {
            color: #0a2a49;
            font-weight: 500;
          }
          color: $banner-blue;
          font: {
            size: rem-calc(25);
            weight: 300;
          }
        }
      }

    	.question-title {
    		display: flex;
    		justify-content: space-between;
    		align-items: center;
    		margin-bottom: 1.5rem;

        h3 {
          font: {
            size: rem-calc(21);
            weight: 300;
          }
        }

    		h3 span {
    			margin-right: 1rem;
    		}

    		a[data-open] {
    			margin-left: 1rem;

    			i {
    				color: $primary-yellow;
    				font: {
    					size: 2rem;
    				}
    			}
    		}
    	}

    	.scale {
    		margin-bottom: 1rem;

    		header {
    			display: flex;
    			justify-content: space-between;
    			margin-bottom: 1rem;
    			font-weight: 100;
    			max-width: rem-calc(200);
    		}

    		.bubble-group {
    			margin-bottom: 1.25rem;
    		}

    		.bubbles {
    			display: flex;
    			justify-content: space-between;
    			max-width: rem-calc(200);

    			> .row {
    				@include breakpoint(small only) {
    					.column {
    						&:first-of-type {
    							order: 1;
    						}
    					}
    				}
    			}

    			.bubble {
    				background: $light-gray;
    				width: 1.75rem;
    				height: 1.75rem;
    				display: flex;
    				justify-content: center;
    				align-items: center;
    				border-radius: 100%;
    				cursor: pointer;
            @include breakpoint(small only) {
              width: 1.5rem;
      				height: 1.5rem;
            }

    				&:hover,
    				&.active {
    					background: $primary-yellow;
    				}

    				&.active {
    					animation: ripple .5s 1;
    				}
    			}
    		}

    		.scale-label {
          color: $primary-blue;
          font: {
            weight: 300;
            size: rem-calc(15);
          }

    			&.invalid {
    				color: tomato;
    				font-weight: bold;

    			}
    		}
    	}
    }
  }

  .inventory-results {
    background: $background-grey;
    padding: rem-calc(50 75 50 75);
    @include breakpoint(small only) {
      padding: rem-calc(50 25 50 25);
    }

    .question-section-header {
      h3 {
        padding-bottom: rem-calc(35);
        span {
          color: #0a2a49;
          font-weight: 500;
        }
        color: $banner-blue;
        font: {
          size: rem-calc(25);
          weight: 300;
        }
      }
    }

    .buttons {
      a {
        margin-right: rem-calc(10);
      }
    }
  }
}

//Samples Slider

#samples-slider-container {
  overflow: hidden;
  position: relative;


  .samples-slider {

    .samples-slide {
      padding: rem-calc(100 0 0 0);

      .container {
        background-position: center center;
        height: rem-calc(180);
        position: relative;

        .slide-info {

          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            line-height: 1.4;
            color: $banner-blue;
            font: {
              size: rem-calc(20);
              weight: 300;
            }
            strong {
              font-weight: 500;
            }
          }

          .icon {
            position: absolute;
            top: -56px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
          }
        }
      }
    }
  }
}
