#faqs {
  padding: rem-calc(75 0);

  .accordion {
    border: none;

    .q-a {
      padding: rem-calc(15 0 0 0);

      a {
        // line-height: $border-lineheight;
        color: $primary-blue;
        border: none;
        border-top: 2px $banner-blue solid;
        padding-top: 30px;
        padding-bottom: 20px;
        font: {
          size: rem-calc(20);
          weight: 300;
        }
        &:focus, &:hover {
          background: none;
          outline: none;
        }
      }

      p {
        font-weight: 300;
        padding-bottom: rem-calc(30);
      }

      .accordion-content {
        border: none;
        .underline {
          // border-bottom: 2px $banner-blue solid;
        }
      }
    }
    .q-a:first-of-type {
      a {
        border-top: none;
      }
    }

  }

  // no border or padding on last child
  .single-faq:nth-last-of-type(1) .q-a {
    border: none;
  }
  .single-faq:nth-last-of-type(1) .q-a p{
    padding-bottom: 0;
  }
}
