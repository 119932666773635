#wsp-products {

  .single-product {
    padding-bottom: rem-calc(75);
    &:first-of-type() {
      padding-top: rem-calc(75);
    }

    .info-container {
      h3 {
        padding-bottom: rem-calc(20);
        font: {
          weight: 500;
        }
      }
      p {
        padding-bottom: rem-calc(20);
        font-weight: 300;
      }

      @include breakpoint(tablet down) {
        padding-top: rem-calc(30);
      }
    }

    .center {
      display: flex;
      justify-content: center;
      @include breakpoint(large) {
        justify-content: flex-end;
      }
    }

    .center-start {
      display: flex;
      justify-content: center;
    }

    .hidden {
      opacity: 0;
    }
  }
}

.reveal {
  width: 100%;
  @include breakpoint(large) {
    width: 800px;
  }

  .topbar {
    position: relative;
    height: rem-calc(70);
    background: $dark-blue;

    button {
      position: absolute;
      top: 50%;
      height: rem-calc(70);
      margin-top: -37px;
      font-size: 3em;
      font-weight: 100;
    }
  }

  .products-slider {

    .products-slide {
      height: rem-calc(555);
    }

    .slick-next, .slick-prev {
      background: $primary-yellow;
      height: rem-calc(70);
      width: rem-calc(60);

      &:before {
        color: $dark-blue;
        font-size: rem-calc(20);
      }
    }

    .slick-next {
      right: 0px;
    }

    .slick-prev {
      left: 0px;
      z-index: 1005;
    }
  }

  .products-description {
    padding: rem-calc(50 0);
    h2 {
      font-size: rem-calc(40);
    }

    .button-container {
      padding-top: rem-calc(40);
    }
  }
}
