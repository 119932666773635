////////////////////////
//  WSP TILES
////////////////////////

.wsp-tile {
  background: $background-grey;
  padding-top: rem-calc(70);
  h3 {
    font: {
      weight: 300;
    }
    line-height: $subheader-lineheight;
    strong {
      font-weight: 500;
    }
  }

  ul {
    color: $secondary-blue;
    li {
      strong {
        font-weight: 500;
      }
    }
  }

  a {
    margin-top: rem-calc(25);
  }

  .img {
    display: flex;
    justify-content: center;
    // opacity: 0;
    @include breakpoint(small only) {
      padding-bottom: 42px;
    }
  }

  @include breakpoint(medium down) {
    .text-container {
      padding-top: rem-calc(30);
    }

  }

}
