// Extends Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group header
////

/// Media query to show full menu
/// @type String
$topbar-breakpoint: medium;

/// Height of topbar at $topbar-breakpoint and up.
/// @type Number
$topbar-height: 65px;

/// Reset the default $topbar-padding value.
/// @type Number
$topbar-padding: 0 rem-calc((map-get($grid-column-gutter, medium) / 2));

/// Height of title bar below $topbar-breakpoint.
/// @type Number
$titlebar-height: 55px;

/// Reset the default $titlebar-padding value.
/// @type Number
$titlebar-padding: 0 rem-calc((map-get($grid-column-gutter, small) / 2));

/// Add nesting to submenu's -- Best used with accordion menu.
/// @type Boolean
$titlebar-nested: false;

.top-nav {
  background: $background-grey;
  padding: rem-calc(5 0);
  a {
    font-size: 12px;
    color: $banner-blue;
    transition: .25s ease-in-out;
    &:hover {
      color: $banner-blue;
      opacity: .5;
    }
  }
}

/// Adds styles for a top bar container.
.top-bar {
  padding: 0;
  background: $white;

  .logo-text a {
    font-size: rem-calc(20);
    line-height: 1em !important;
    margin-left: rem-calc(70);
    display: block;

    span {
      font-weight: bold;
    }
  }

  // Desktop Dropdown on What is WSP
  .desktop-dp {
    display: none;
    position: absolute;
    top: 66px;
    padding: rem-calc(45 0);
    width: 300px;
    background: $dark-blue;
    .anchor-list {
      background: $dark-blue;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      list-style-type: none;
      li {
        max-width: 30ch;
        line-height: $lead-lineheight;
        padding-bottom: rem-calc(25);
        a {
          color: $white;
          font: {
            weight: 300;
            size: rem-calc(15);
          }
          transition: .25s ease-in-out;
          &:hover {
            opacity: .5;
          }
        }
      }
    }
  }

  .top-bar-row {
    @include flex;
    width: 100%;
    max-width: $global-width;
    justify-content: space-between;
    align-items: center;
    ul {
      background: $white;
      a {
        color: $banner-blue;
      }
    }
  }

  // Styles for $topbar-breakpoint and up should go here
  @include breakpoint($topbar-breakpoint) {
    height: $topbar-height;

    .top-bar-row {
      .menu-items,
      .menu-logo {
        > li {
          transition: .25s ease-in-out;
          &:hover {
            background: $primary-yellow;
            color: $banner-blue;
          }
          &:hover:nth-last-of-type(1) {
            background: none;
          }
          &:hover a {
            color: $banner-blue;
          }
          > a {
            padding: $topbar-padding;
            line-height: $topbar-height;
            color: $banner-blue;

            .logo-container {
              background: $primary-blue;
              position: fixed;
              top: 0;
              padding: rem-calc(30 10 10 10);
              z-index: 9999;
              img {
                max-height: $topbar-height;
              }
            }
          }
        }
      }
      .menu .active > a {
          background: none;
          // color: $banner-blue;
      }
    }
  }
  // DROPDOWN
  .drop-down {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    transition: width .5s ease-in-out;
    background: $primary-blue;
    .container {
      height: 100vh;
      padding: 50px 0px 0px 30px;
      ul {
        background: $primary-blue;

        .home-nav {
          a:after {
            padding-left: 10px;
            content: '\f015';
            font-family: fontAwesome;
            color: $white;
          }
        }

        a {
          color: $white;
          font-size: rem-calc(16);
          display: inline-block;
          position: relative;
          transition: .25s ease-in-out;
          &:hover {
            opacity: .5;
          }
        }
        .active > a {
          background: none;
          color: #fff;
        }
        .is-accordion-submenu-parent > a::after {
          content: '\f107';
          font-family: 'fontAwesome';
          font-size: rem-calc(20);
          border: none;
          position: absolute;
          right: 0;
          color: $primary-yellow;
        }
        .is-accordion-submenu {
          a {
            color: $third-blue;
          }
        }

        .help {
          padding-top: rem-calc(65);
        }
      }
    }
  }
}


/// Adds styles for a title bar container.
.title-bar {
  padding: $titlebar-padding;
  padding-top: rem-calc(40);
  padding-bottom: rem-calc(40);
  height: $titlebar-height;
  background: $white;

  .title-bar-left {
    a {
      padding: 0;

      .logo-container {
        background: $primary-blue;
        position: absolute;
        top: 34px;
        padding: rem-calc(30 10 10 10);
        z-index: 9999;
        img {
          max-height: $titlebar-height;
        }
      }
    }
  }

  .title-bar-right {
    .menu {
      li {
        flex-grow: 1;
        .menu-icon {
          &:after {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 2px;
            background: $dark-blue;
            -webkit-box-shadow: 0 7px 0 $dark-blue, 0 14px 0 $dark-blue;
            box-shadow: 0 7px 0 $dark-blue, 0 14px 0 $dark-blue;
            content: '';
          }
        }
      }
    }

    .hamburger-container {
      float: right;
    }
  }
}

.menu .active > a {
  background: none;
  color: $banner-blue;
}

/// Adds nesting to submenu's if $title-bar nestes is true.
@if $titlebar-nested == true {
  @include breakpoint($topbar-breakpoint) {
    .top-bar .submenu {
      padding-left: 1rem;
    }
  }
}

/// Fixes the split-second flicker of the title bar on desktop
/// You may need to adjust the breakpoints for your project
.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

////////////////////////
//  ADDITIONAL STYLES
////////////////////////

// Headroom
.headroom {
    will-change: transform;
    transition: transform 200ms linear;
    position: relative;
    z-index: 1004;
}
.headroom--pinned {
    transform: translateY(0%);
    position: relative;
    z-index: 1004;
}
.headroom--unpinned {
    transform: translateY(-165%);
    position: relative;
    z-index: 1004;
}

.stuck {
  position:fixed;
  top:0;
  width: 100%;
  z-index: 1004;
  // z-index: 9999;
}

// Hamburger menu
$bar-width: 20px;
$bar-height: 2px;
$bar-spacing: 5px;

.hamburger-menu, .close-hamburger {
  margin: auto;
  width: $bar-width;
	height: $bar-height + $bar-spacing*2;
	cursor: pointer;
  margin: 0 0.9375rem;
}

.bar,
.bar:after,
.bar:before {
  width: $bar-width;
	height: $bar-height;
}

.bar {
	position: relative;
	transform: translateY($bar-spacing);
	background: rgba(255, 255, 255, 1);
	transition: all 0ms 300ms;
  background: $dark-blue;

  &.animate, {
    background: rgba(255, 255, 255, 0);
  }
}

.bar:before {
	content: "";
	position: absolute;
	left: 0;
	bottom: $bar-spacing;
	background: $dark-blue;
	transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar:after {
	content: "";
	position: absolute;
	left: 0;
	top: $bar-spacing;
	background: $dark-blue;
	transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar.animate:after {
	top: 0;
	transform: rotate(45deg);
	transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}

.bar.animate:before {
	bottom: 0;
	transform: rotate(-45deg);
	transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}

// Close Hamburger
.close-hamburger {
  position: absolute;
  right: 30px;
  top: 30px;
  .bar:before, .bar:after {
  	background: $primary-yellow;
  }
}

.active {
  height: 100%;
  margin: 0;
  overflow: hidden;
}







.drop-down {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  transition: width .5s ease-in-out;
  background: $primary-blue;
  z-index: 10;
  .container {
    height: 100vh;
    padding: 50px 0px 0px 30px;
    ul {
      background: $primary-blue;
      a {
        color: $white;
        font-size: rem-calc(15);
        display: inline-block;
        position: relative;
        transition: .25s ease-in-out;
        &:hover {
          opacity: .5;
        }
      }
      .active > a {
        background: none;
        color: #fff;
      }
      .is-accordion-submenu-parent > a::after {
        content: '\f107';
        font-family: 'fontAwesome';
        font-size: rem-calc(20);
        border: none;
        position: absolute;
        right: 0;
        color: $primary-yellow;
      }
      .is-accordion-submenu {
        a {
          color: $third-blue;
        }
      }

      .help {
        padding-top: rem-calc(65);
      }

    }
  }
}
